export default ({ peFetch }) => ({
  getProjects: async () => {
    return peFetch.get(`/api/bookflow/v1/projects`);
  },

  getProject: (project_id) => {
    return peFetch.get(`/api/bookflow/v1/project/${project_id}`);
  },

  saveProject: (data) => {
    return peFetch.post(`/api/bookflow/v1/project`, data);
  },

  updateProject: (project_id, data) => {
    return peFetch.patch(`/api/bookflow/v1/project/${project_id}`, data);
  },

  searchProjects: async (q) => {
    return peFetch.get(`/api/bookflow/v1/search/projects`, {
      params: { q },
    });
  },

  createNote: (project_id, data) => {
    return peFetch.post(`/api/bookflow/v1/project/${project_id}/note`, data);
  },

  createCharacter: (project_id, data) => {
    return peFetch.post(
      `/api/bookflow/v1/project/${project_id}/character`,
      data
    );
  },

  updateNote: (project_id, note_id, data) => {
    return peFetch.patch(
      `/api/bookflow/v1/project/${project_id}/note/${note_id}`,
      data
    );
  },
  updateCharacter: (project_id, character_id, data) => {
    return peFetch.patch(
      `/api/bookflow/v1/project/${project_id}/character/${character_id}`,
      data
    );
  },

  deleteNote: (project_id, note_id) => {
    return peFetch.delete(
      `/api/bookflow/v1/project/${project_id}/note/${note_id}`
    );
  },
  deleteCharacter: (project_id, character_id) => {
    return peFetch.delete(
      `/api/bookflow/v1/project/${project_id}/character/${character_id}`
    );
  },
  deleteProject: (project_id) => {
    return peFetch.delete(`/api/bookflow/v1/project/${project_id}`);
  },

  getProjects: async () => peFetch.get(`/api/bookflow/v1/projects`),

  getProject: (project_id) =>
    peFetch.get(`/api/bookflow/v1/project/${project_id}`),

  searchProjectNotes: (project_id, term, page, page_size) => {
    return peFetch.get(`/api/bookflow/v1/search/project/${project_id}/notes`, {
      params: { q: term },
    });
  },
  searchProjectNote: (project_id, note_id) => {
    return peFetch.get(
      `/api/bookflow/v1/search/project/${project_id}/note/${note_id}`
    );
  },
  searchProjectCharacters: (project_id, term, page, page_size) => {
    return peFetch.get(
      `/api/bookflow/v1/search/project/${project_id}/characters`,
      { params: { q: term } }
    );
  },
  searchProjectCharacter: (project_id, character_id) => {
    return peFetch.get(
      `/api/bookflow/v1/search/project/${project_id}/character/${character_id}`
    );
  },
});
