export const ROUTE_PARAM = {
  APP_ID: 'APP_ID',
};

export const ROUTE = {
  HOME: '/',
  LOGIN: '/:APP_ID',
  SIGN_UP: '/:APP_ID/signup',
  VERIFY_EMAIL: '/:APP_ID/verify',
};

export const getRoutePath = (route, params) => {
  let path = route;
  for (let k in params) {
    path = path.replace(`:${k}`, params[k]);
  }
  return path;
};
