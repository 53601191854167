const baseServiceUrl = '/api/gender-reveal/v1';

export default ({ peFetch }) => ({
  createEvent: async ({ name, email, isBoy = false, isGirl = false }) => {
    return peFetch.post(`${baseServiceUrl}/public/create-event`, {
      name,
      email,
      isBoy,
      isGirl,
    });
  },
  addEventParticipant: async ({ eventUUID, name, email }) => {
    return peFetch.post(
      `${baseServiceUrl}/${eventUUID}/add-event-participant`,
      {
        name,
        email,
      }
    );
  },
  removeEventParticipant: async ({ eventUUID, participantUUID }) => {
    return peFetch.delete(
      `${baseServiceUrl}/${eventUUID}/participant/${participantUUID}`
    );
  },

  addEventParticipantVote: async ({
    eventUUID,
    participantUUID,
    isBoy,
    isGirl,
  }) => {
    return peFetch.post(
      `${baseServiceUrl}/${eventUUID}/participant/${participantUUID}/vote`,
      {
        eventUUID,
        participantUUID,
        isBoy,
        isGirl,
      }
    );
  },

  getEventView: async ({
    eventUUID,
    organizerName,
    participantUUID,
    withAnswer = false,
    withParticipants = false,
    withVotes = false,
    withOrganizer = false,
  }) => {
    return peFetch.post(`${baseServiceUrl}/${eventUUID}/view`, {
      eventUUID,
      participantUUID,
      organizerName,
      withAnswer,
      withParticipants,
      withVotes,
      withOrganizer,
    });
  },

  addEventAnswer: async ({ eventUUID, isBoy, isGirl }) => {
    return peFetch.post(`${baseServiceUrl}/${eventUUID}/answer`, {
      eventUUID,
      isBoy,
      isGirl,
    });
  },
});
