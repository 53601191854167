import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import { PRODUCT_IMAGE, usePickExpApi } from '@pickexp/core';
import { useParams } from 'react-router-dom';
import { useQuery } from '../../hooks';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from '../../constants/routes';

const VerifyView = (props) => {
  let navigate = useNavigate();
  let query = useQuery();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const api = usePickExpApi();

  const authToken = query.get('t');
  const { verifyEmail } = api.getCoreApiURLs();
  const { APP_ID } = useParams();
  const productLogo = PRODUCT_IMAGE[APP_ID].loginLogo;
  const verify = useCallback(async () => {
    let result = { data: {} };

    try {
      result = await api.fetch.get(verifyEmail, {
        params: { t: authToken },
      });
    } catch (e) {}

    //Email Verified
    //'Something went wrong please contact us at <b>support@pickexp.com</b>'
    if (result.data.status === 'Success') {
      setIsSuccess(true);
    }

    setHasLoaded(true);
  }, [api, authToken, verifyEmail]);

  useEffect(() => {
    !hasLoaded && verify();
  }, [hasLoaded, verify]);

  return (
    <div className={`login-view--${APP_ID}`}>
      <div className="verify-view">
        {hasLoaded && isSuccess && (
          <div className="successMessage">
            <DoneIcon />
            <div className="text">Email Verified</div>
            <button
              type="button"
              className="pe-button-white"
              onClick={() => {
                navigate(`/${APP_ID}`);
              }}
            >
              Log In
            </button>
          </div>
        )}
        {hasLoaded && !isSuccess && (
          <div className="errorMessage">
            <ErrorOutlineIcon />
            <div className="text">You email has already been verified</div>
            <button
              type="button"
              className="pe-button-white"
              onClick={() => {
                navigate(`/${APP_ID}`);
              }}
            >
              Log In
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyView;
