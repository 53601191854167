export default ({ peFetch }) => ({
  getSources: async (type) => {
    return peFetch.get(
      `/api/simple-wallet/v1/sources${
        type ? `?type=${type.toUpperCase()}` : ''
      }`
    );
  },
  addRecord: async (data) => {
    return peFetch.post(`/api/simple-wallet/v1/record`, data);
  },
});
