const baseServiceUrl = '/api/pickexp/v1';

export default ({ peFetch }) => {
  return {
    checkIn: async (token) => {
      localStorage.setItem('stk', token);
      const r = await peFetch.get(`${baseServiceUrl}/checkIn`);
      if (r.status === 200) {
        window.location.href = '/';
      } else {
        //this.logout(true);
      }
    },
    autoSignIn(to, next) {
      if (SessionService.isValid()) {
        this.getUserProfile().then((r) => {
          if (r.data) {
            const peuser = {
              name: '',
              email: '',
              phone: '',
            };
            if (r.data.first_name) {
              peuser.name += r.data.first_name;
            }
            if (r.data.last_name) {
              peuser.name += ' ' + r.data.last_name;
            }
            if (r.data.email) {
              peuser.email = r.data.email;
            }
            this.setLocalStorageSessionData('peuser', peuser);
          }
          next();
        });
        SessionService.canAccessRoute(to, next);
      } else {
        console.log('NO VALID');
        //SessionService.logout();
      }
    },

    setLocalStorageSessionData(name, data) {
      if (typeof data === 'object') {
        localStorage.setItem(name, JSON.stringify(data));
      } else {
        localStorage.setItem(name, data);
      }
    },
    removeLocalStorageSessionData(name, data) {
      localStorage.removeItem('peuser');
    },
    getUserProfile() {
      return HttpService.get(`${baseServiceUrl}/user/profile`);
    },

    getUserInfo() {
      const peuser = localStorage.getItem('peuser');
      if (peuser) {
        return JSON.parse(peuser);
      } else {
        return {};
      }
    },

    isValid() {
      const stk = localStorage.getItem('stk');
      return stk && stk.length > 200 && stk.split('.').length === 3
        ? true
        : false;
    },

    logout(back) {
      localStorage.removeItem('stk');
      this.removeLocalStorageSessionData();
      if (back) {
        window.history.back();
      }
      window.location.href = this.PE_CONFIG.website.baseurl;
    },

    canAccessRoute(to, next) {
      next();
    },

    //UserService
    getUser(type, name) {
      return new Promise((resolve, reject) => {
        let url = `${AppConfig.api.baseurl} ${baseServiceUrl}/user/${type}`;
        if (name) {
          url += '/' + name;
        }
        HttpService.get(url).then((r) => {
          if (r.data) {
            if (Array.isArray(r.data)) {
              resolve(r.data);
            } else if (r.data.metadata) {
              resolve(r.data.metadata);
            } else {
              reject();
            }
          } else {
            reject();
          }
        });
      });
    },
    //AccessService
    init(cb) {
      if (!this.list || this.list.length === 0) {
        this.list = [];
        this.objlist = {};
        HttpService.get(`${baseServiceUrl}/access/functionality`).then((r) => {
          this.list = r.data;
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].name) {
              this.objlist[this.list[i].name] = true;
            }
          }
          if (cb) {
            cb();
          }
        });
      } else {
        if (cb) {
          cb();
        }
      }
    },

    initPublic(cb) {
      if (!this.list || this.list.length === 0) {
        this.list = [];
        this.objlist = {};
        HttpService.get(`${baseServiceUrl}/public/access/functionality`).then(
          (r) => {
            this.list = r.data;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].name) {
                this.objlist[this.list[i].name] = true;
              }
            }
            if (cb) {
              cb();
            }
          }
        );
      } else {
        if (cb) {
          cb();
        }
      }
    },

    has(name) {
      if (this.objlist[name]) {
        return this.objlist[name];
      } else {
        return false;
      }
    },
    urls: () => ({
      sendEmail: '/sv/sendemail.php',
      templateConfig: '/sv/templateconfig.php',
      templates: '/sv/templates.php',
      login: '/sv/auth.php',
      uploads: '/sv/uploads',
      image: '/sv/image',
      signIn: `${baseServiceUrl}/auth/signIn`,
      signUp: `${baseServiceUrl}/auth/signUp`,
      verifyTwoFactor: `${baseServiceUrl}/auth/verify-two-factor-code`,
      verifyEmail: `${baseServiceUrl}/auth/verify-email`,
      getDocument: `${baseServiceUrl}/document`,
    }),
  };
};
