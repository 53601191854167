import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { PickexpApi, PickExpApiContext } from '@pickexp/core';
import { getAPIConfig } from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <PickExpApiContext.Provider
      value={PickexpApi.getBaseApis({ API_CONFIG: getAPIConfig() })}
    >
      <Router>
        <App />
      </Router>
    </PickExpApiContext.Provider>
  </React.StrictMode>
);
