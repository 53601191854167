const baseServiceUrl = '/api/me/v1';

export default ({ peFetch }) => ({
  createOrUpdateCustomer: async ({
    uuid,
    firstName,
    lastName,
    email,
    plans,
  }) => {
    return uuid
      ? peFetch.patch(`${baseServiceUrl}/customer/${uuid}`, {
          firstName,
          lastName,
          email,
          plans,
        })
      : peFetch.post(`${baseServiceUrl}/customer`, {
          firstName,
          lastName,
          email,
        });
  },
  deleteCustomer: async ({ uuid }) => {
    return peFetch.delete(`${baseServiceUrl}/customer/${uuid}`);
  },
  getCustomer: async ({
    uuid,
    withHistory = false,
    withUpcomingActivities = false,
  }) => {
    return peFetch.post(`${baseServiceUrl}/customer/${uuid}`, {
      withHistory,
      withUpcomingActivities,
    });
  },
  deleteInstructor: async ({ uuid }) => {
    return peFetch.delete(`${baseServiceUrl}/instructor/${uuid}`);
  },
  createOrUpdatePlan: async ({
    uuid,
    name,
    isActive,
    description,
    amount,
    duration,
    sessions,
    sessionTypes,
  }) => {
    return uuid
      ? peFetch.patch(`${baseServiceUrl}/plan/${uuid}`, {
          name,
          isActive,
          description,
          amount,
          duration,
          sessions,
          sessionTypes,
        })
      : peFetch.post(`${baseServiceUrl}/plan`, {
          name,
          description,
          amount,
          duration,
          sessions,
          sessionTypes,
        });
  },
  confirmSessionAttendants: async ({ list }) => {
    return peFetch.post(`${baseServiceUrl}/session/confirm_attendants`, {
      list,
    });
  },

  createOrUpdateSessionType: async ({ uuid, name }) => {
    return uuid
      ? peFetch.patch(`${baseServiceUrl}/session_type/${uuid}`, {
          name,
        })
      : peFetch.post(`${baseServiceUrl}/session_type`, {
          name,
        });
  },

  deleteSessionType: async ({ uuid }) => {
    return peFetch.delete(`${baseServiceUrl}/session_type/${uuid}`);
  },
  deleteSession: async ({ uuid }) => {
    return peFetch.delete(`${baseServiceUrl}/session/${uuid}`);
  },

  createOrUpdateSession: async ({
    uuid,
    name,
    instructorUUID,
    spots,
    startDate,
    endDate,
    description,
    typeId,
    isCanceled,
    isActive,
    metadata,
    weekDays,
  }) => {
    return uuid
      ? peFetch.patch(`${baseServiceUrl}/session/${uuid}`, {
          name,
          instructorUUID,
          spots,
          startDate,
          endDate,
          description,
          typeId,
          isCanceled,
          metadata,
          weekdays: weekDays,
          isActive,
        })
      : peFetch.post(`${baseServiceUrl}/session`, {
          name,
          instructorUUID,
          spots,
          startDate,
          endDate,
          description,
          typeId,
          metadata,
          weekdays: weekDays,
        });
  },

  createReservation: async ({
    orgUUID,
    sessionUUID,
    firstName,
    lastName,
    email,
    startDate,
    endDate,
    feStartDate,
    feEndDate,
    deviceSessionUUID,
  }) => {
    const ctzd = new Date().getTimezoneOffset();
    return peFetch.post(`${baseServiceUrl}/public/reservation`, {
      orgUUID,
      sessionUUID,
      firstName,
      lastName,
      email,
      startDate,
      endDate,
      feStartDate,
      feEndDate,
      deviceSessionUUID,
      ctzd,
    });
  },

  getCustomerBookableSessions: async ({
    email,
    orgUUID,
    startDate,
    endDate,
    weekDay,
  }) => {
    return peFetch.get(`${baseServiceUrl}/public/customer/bookable_sessions`, {
      params: { email, orgUUID, startDate, endDate, weekDay },
    });
  },

  getPublicCustomerInfo: async ({ email, orgUUID }) => {
    return peFetch.post(
      `${baseServiceUrl}/public/org/${orgUUID}/customer/info`,
      { email }
    );
  },

  getCustomerReservedSessions: async ({ email, orgUUID }) => {
    return peFetch.post(`${baseServiceUrl}/public/customer/reserved_sessions`, {
      email,
      orgUUID,
    });
  },

  getCustomers: async ({ q, field, status }) => {
    const query = {};
    if (q) {
      query.q = q;
    }
    if (field) {
      query.field = field;
    }
    return peFetch.post(`${baseServiceUrl}/customers`, {
      ...query,
      options: {
        status: status || 'ACTIVE',
      },
    });
  },

  getInstructors: async ({ q, field }) => {
    const query = {};
    if (q) {
      query.q = q;
    }
    if (field) {
      query.field = field;
    }
    return peFetch.get(`${baseServiceUrl}/instructors`, {
      params: query,
    });
  },

  createOrUpdateInstructor: async ({ uuid, firstName, lastName, email }) => {
    return uuid
      ? peFetch.patch(`${baseServiceUrl}/instructor/${uuid}`, {
          firstName,
          lastName,
          email,
        })
      : peFetch.post(`${baseServiceUrl}/instructor`, {
          firstName,
          lastName,
          email,
        });
  },

  getSessionsByDateRange: async ({
    startDate,
    endDate,
    weekDay,
    syncSessions,
    withStatusInactive = false,
  }) => {
    return peFetch.get(`${baseServiceUrl}/sessions`, {
      params: {
        startDate,
        endDate,
        weekDay,
        syncSessions,
        withStatusInactive,
      },
    });
  },

  getSessionsByUUID: async ({ uuid }) => {
    return peFetch.get(`${baseServiceUrl}/session/${uuid}`);
  },

  getPlans: async () => {
    return peFetch.get(`${baseServiceUrl}/plans`);
  },

  getSessionTypes: async () => {
    return peFetch.get(`${baseServiceUrl}/session_types`);
  },

  purchasePlan: async ({ startDate, endDate, customerUUID, planId }) => {
    return peFetch.post(`${baseServiceUrl}/activity`, {
      startDate,
      endDate,
      customerUUID,
      planId,
      type: 'ADD',
      reason: 'PLAN_PURCHASE',
    });
  },

  getSessionCustomers: async ({ sessionUUID }) => {
    return peFetch.get(`${baseServiceUrl}/session/${sessionUUID}/customers`);
  },

  getSessionCustomersByRange: async ({ sessionUUID, startDate, endDate }) => {
    return peFetch.post(
      `${baseServiceUrl}/session/${sessionUUID}/customersByRange`,
      {
        startDate,
        endDate,
      }
    );
  },

  getSessionCustomersByRangeView: async ({}) => {
    return peFetch.post(
      `${baseServiceUrl}/session/getSessionCustomersByRangeView`
    );
  },

  generateCustomersByRangeLink: async ({ sessionUUID, startDate, endDate }) => {
    return peFetch.post(
      `${baseServiceUrl}/session/${sessionUUID}/generateCustomersByRangeLink`,
      {
        startDate,
        endDate,
      }
    );
  },

  getPublicOrgConfigByCustomer: async ({ email, orgUUID }) => {
    return peFetch.post(`${baseServiceUrl}/public/org/configs`, {
      email,
      orgUUID,
    });
  },

  updateOrgConfigs: async (payload) => {
    return peFetch.patch(`${baseServiceUrl}/org/config`, { configs: payload });
  },

  cancelSession: async ({
    customerUUID,
    sessionActivityUUID,
    isRefund,
    reason,
  }) => {
    const ctzd = new Date().getTimezoneOffset();
    return peFetch.post(`${baseServiceUrl}/customer/${customerUUID}/cancel`, {
      reason,
      sessionActivityUUID,
      isRefund,
      ctzd,
    });
  },
  publicCustomerCancelSession: async ({
    orgUUID,
    customerUUID,
    sessionActivityUUID,
  }) => {
    const ctzd = new Date().getTimezoneOffset();
    return peFetch.post(
      `${baseServiceUrl}/public/org/${orgUUID}/customer/${customerUUID}/cancel-session/${sessionActivityUUID}`,
      {
        ctzd,
      }
    );
  },

  adjustCustomerPlan: async ({
    customerUUID,
    planUUID,
    newPlanExpDate,
    adjustAmount = 0,
    isAdd,
    activityId,
  }) => {
    return peFetch.post(
      `${baseServiceUrl}/customer/${customerUUID}/adjustCustomerPlan`,
      {
        planUUID,
        newPlanExpDate,
        adjustAmount,
        isAdd,
        activityId,
      }
    );
  },

  getEntity: async ({
    withInfo,
    withInfoUUID,
    withConfigs,
    withEmailConfig,
    withEmailTemplates,
  }) => {
    return peFetch.post(`${baseServiceUrl}/entity`, {
      getWith: {
        withInfo,
        withInfoUUID,
        withConfigs,
        withEmailConfig,
        withEmailTemplates,
      },
    });
  },
});
